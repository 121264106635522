import React from "react"
import TutorialsLayout from "../../components/tutorials-layout"
import Seo from "../../components/seo"
import starsPreview from "../../images/tutorials/stars-preview.gif"
import {
  animatedStars,
  animatedStarsIndices,
  getPainterMethodFinal,
  getStaticPainterMethod,
  starsLayoutCode1,
  starsPainterFinal,
  staticStars,
  staticStarsContainer,
  staticStarsPainter,
} from "../../data/code/stars-tutorial-code"
import { Link } from "gatsby"
import PrismCode from "../../components/prims/PrismCode"
import staticStarsImage from "../../images/tutorials/static-stars.png"
import GetTheAppSection from "../../components/get-the-app-section/GetTheAppSection"
import ShareArticle from "../../components/share-article/ShareArticle"
import Pagination from "../../components/pagination/Pagination"
import { sidebarLinks } from "../../data"

const BackgroundStarsPage = () => {
  return (
    <>
      <Seo
        title="Background Stars"
        titleSuffix="Dashtronaut Tutorials"
        image="https://dashtronaut.app/images/background-stars-tutorial.png"
      />
      <h1>The Background Stars</h1>
      <h4>Creating and Animating the Stars Using CustomPainter</h4>
      <p className="mt-5">
        Welcome to one of the{" "}
        <strong>
          <Link to="/">Dashtronaut</Link>
        </strong>{" "}
        app tutorials, in this one, we will create and animate the stars you see
        in the background of the game using Flutter’s{" "}
        <strong>CustomPainter</strong>. Here’s the final result:
      </p>
      <img src={starsPreview} alt="Stars Preview" />
      <p className="my-4">
        <strong>TL, DR;</strong>
        <br /> You can check out the code for this feature alone in{" "}
        <a
          href="https://dartpad.dev/?id=03040d593325a1cc077180dbd4e5727d"
          target="_blank"
          rel="noreferrer"
        >
          this DartPad
        </a>
        . We will talk in later tutorials about how it was integrated and used
        in the puzzle’s source code.
      </p>
      <h1>Planning</h1>
      <p>
        Let’s start some planning by considering the task and figuring out what
        we need to implement.
      </p>
      <ol>
        <li>
          The painting part:
          <ul>
            <li>Tiny circles laid out on the screen</li>
            <li>
              The circles are positioned at random x and y locations (offsets)
            </li>
            <li>
              Those x and y offsets should cover all and not exceed the screen
              width and height
            </li>
            <li>The circles have random tiny sizes</li>
            <li>The star count should change based on screen size.</li>
          </ul>
        </li>
        <li>
          The animation part:
          <ul>
            <li>
              We should keep in mind that the animation should be subtle so as
              to not be very distracting or straining, especially that in our
              case there is a lot going on around it with the puzzle and its
              surrounding UI. (Feel free to adjust the animation based on your
              use case)
            </li>
            <li>So we will target the opacity of the stars</li>
            <li>
              We need 2 lists of indices, one for stars that fade-in, and one
              for stars that fade out. The fade-in/fade-out will happen to
              separate stars but at the same time, creating a semi-realistic
              effect
            </li>
          </ul>
        </li>
      </ol>
      <p>Okay, enough planning, time for some code!</p>
      <h1>1. The Painting</h1>
      <h2>1.1. StarsLayout Class</h2>
      <p>
        In these cases I like to create helper classes that I can dump all the
        logic in. So we’ll create a StarsLayout class and give it the
        BuildContext as a required field. After all, we talked a lot in the
        planning about the “screen size”, so we will definitely need the
        context. So here’s the class with what we need for the painting part
      </p>
      <PrismCode code={starsLayoutCode1} />
      <p>The code breakdown:</p>
      <ul>
        <li>
          <strong>Line 6</strong>: we created a screenSize getter because we
          will use it a lot
        </li>
        <li>
          <strong>Line 9</strong>: getter for the count of the stars based on
          screen width
        </li>
        <li>
          <strong>Line 21</strong>: Random generator initialized
        </li>
        <li>
          <strong>Lines 23 => 35</strong>: getting the max values for the x and
          y offsets of the stars such that they don’t exceed the screen’s width
          and height. These values are converted to an int because they will be
          given to the Random generator’s “max” param, which only works with the
          <strong>random.nextInt()</strong> function
        </li>
        <li>
          <strong>Line 37</strong>: Function that generates a list of random
          integers. The list has a length equal to the stars count. And the
          random integers range between 1 & the provided max value
        </li>
        <li>
          <strong>Line 41</strong>: list of random x offsets of type int with
          the max value of the starsMaxXOffset
        </li>
        <li>
          <strong>Line 45</strong>: list of random y offsets of type int with
          the max value of the starsMaxYOffset
        </li>
        <li>
          <strong>Line 49</strong>: list of random sizes of type double. The
          random.nextDouble() has a range between 0.0 => 1.0, so by adding the
          0.7, we make the range 0.7 => 1.7 so that we don’t have 0 or very
          small sizes for the stars
        </li>
      </ul>
      <h2>1.2 The CustomPainter</h2>
      <p>
        Now we have the necessary logic to create our CustomPainter like so:
      </p>
      <PrismCode code={staticStarsPainter} />
      <p>
        We simply initialized a <strong>Paint</strong> class and gave it a
        temporary white color (this will be modified later with the animation).
        And with a for loop matching the <strong>totalStarsCount</strong>, we
        drew circles with the <strong>canvas.drawCircle()</strong> method and
        gave them their corresponding x and y offsets as well as sizes. These
        will be the lists we created in the <strong>StarsLayout</strong> class.
      </p>
      <p>
        To be able to use these lists easily, we will add a{" "}
        <strong>getPainter()</strong> method to our <strong>StarsLayout</strong>{" "}
        class like so
      </p>
      <PrismCode code={getStaticPainterMethod} />
      <p>
        Now we can create our <strong>Stars</strong> widget like so
      </p>
      <PrismCode code={staticStars} />
      <p>
        Make sure that when you use the Stars widget, you put it in a Container
        with width and height values equal to the screen’s width and height.
        Here we’re adding a gradient as well for a better style:
      </p>
      <PrismCode code={staticStarsContainer} />
      <p>At this point the output should look like this:</p>
      <img src={staticStarsImage} alt="" />
      <hr className="my-5" />
      <h1>2. The Animation</h1>
      <h2>2.1. Updating the StarsLayout Class</h2>
      <p>
        If you remember from the planning, we decided that we needed 2 lists of
        indices of stars, one will be for the stars with a fade in animation,
        and the other for the stars with a fade out animation. We can define
        these lists and add them to our <strong>StarsLayout</strong> class as
        follows:
      </p>
      <PrismCode code={animatedStarsIndices} />
      <p>
        So now basically every (5 * n)th star is a fade-out star, and every (3 *
        n)rd star is a fade-in star.
      </p>
      <p>
        We can now add those two lists to the CustomPainter class’s fields and
        then to the <strong>StarsLayout</strong>’s <strong>getPainter()</strong>{" "}
        method.
      </p>
      <p>
        To create the animation, we need to convert the Stars widget to a{" "}
        <strong>StatefulWidget</strong> and add the animation code to it as
        follows:
      </p>
      <PrismCode code={animatedStars} />
      <p>
        <strong>The code breakdown:</strong>
        <br />
        First thing you need to do when you want to create your own animation,
        is define an <strong>AnimationController</strong> (
        <strong>line 14</strong>). Here we defined our{" "}
        <strong>AnimationController</strong>, and by adding the{" "}
        <strong>SingleTickerProviderStateMixin</strong> (<strong>line 8</strong>
        ) we were able to give <strong>`this`</strong> as the required{" "}
        <strong>`vsync`</strong> value of the controller. You don’t need to
        worry about why we did this, basically the vsync keeps track of the
        screen so that the animation doesn't run when it's not visible.
      </p>
      <p>
        We also added a duration to our AnimationController (line 16), and made
        it repeat with reverse set to true (line 18), so now instead of
        animating the opacity for example from 0 to 1, then from 0 to 1, …etc,
        it will animate it 0 => 1 => 0 => 1, …etc.
      </p>
      <p>
        Next thing we did is create our custom opacity animation using a{" "}
        <strong>Tween</strong>(<strong>line 20</strong>), which basically gives
        us the ability to animate between 2 values of any type, in our case,
        double. We considered only the fade-out animation now by giving the
        tween a begin value of 0.8 and end value of 0.1. You’ll see later how we
        create the fade-in.
      </p>
      <p>
        We linked our animation with the <strong>AnimationController</strong> by
        calling the <strong>animate()</strong> method on the Tween and giving it
        a <strong>CurvedAnimation</strong> with an <strong>easeInOut</strong>{" "}
        curve for a smooth nonlinear animation.
      </p>
      <p>
        Of course, with an <strong>AnimationController</strong>, we should make
        sure to <strong>dispose()</strong> it in the widget’s dispose lifecycle
        method (<strong>line 32</strong>).
      </p>
      <p>
        Then we simply passed our <strong>_opacity</strong> animation into our
        CustomPainter by passing it to the <strong>StarsLayout</strong>’s{" "}
        <strong>getPainter()</strong> method (line 42). That method now should
        be like so:
      </p>
      <PrismCode code={getPainterMethodFinal} />
      <p>
        Now let’s see how we used this animation in our{" "}
        <strong>CustomPainter</strong>:
      </p>
      <PrismCode code={starsPainterFinal} />
      <p>
        <strong>The code breakdown:</strong>
        <br />
        Conveniently, the <strong>CustomPainter</strong> class takes a{" "}
        <strong>Listenable</strong> field `<strong>repaint</strong>` that
        triggers repainting the CustomPainter whenever that listenable receives
        a new value. This works great for us since our opacity{" "}
        <a
          href="https://api.flutter.dev/flutter/animation/Animation-class.html"
          target="_blank"
          rel="noreferrer"
        >
          Animation class extends a Listenable
        </a>
        . And that’s why we passed our opacity animation to our{" "}
        <strong>StarsPainter</strong> and then to the super class (
        <strong>line 18</strong>).
        <br />
        Notice that our <strong>shouldRepaint</strong> method still returns
        false (<strong>line 45</strong>), because it doesn't need to return true
        when we’ve already assigned a Listenable to the super class’s repaint
        parameter.
      </p>
      <p>
        To make the opacity animation actually work, we created the{" "}
        <strong>_getStarOpacity</strong> function that takes an index, if that
        index exists in the <strong>fadeOutStarsIndices</strong> list, then it
        should return the <strong>opacityAnimation.value</strong> as an opacity
        (<strong>line 24</strong>). And if that index exists in the{" "}
        <strong>fadeInStarsIndices</strong>, then it should return (
        <strong>1 - opacityAnimation.value</strong>) as an opacity (
        <strong>line 26</strong>). This allows the animation to animate between
        0.2 and 0.9 (a fade-in) instead of between 0.8 and 0.1 (the original
        fade-out). If that index doesn't belong to either lists, return a fixed
        opacity.
      </p>
      <p>
        Now we simply call this function when we assign color to our Paint
        before drawing each circle (<strong>line 35</strong>)
      </p>
      <p>And that’s it! You now have cool animated stars!</p>
      <p>
        <a
          href="https://dartpad.dev/?id=03040d593325a1cc077180dbd4e5727d"
          target="_blank"
          rel="noreferrer"
        >
          Checkout the DartPad and see the code above in action!
        </a>
      </p>
      <p>
        Stay tuned for more tutorials for features in the app! Me and
        Dashtronaut are working on them right now 💙
      </p>
      <Pagination
        prevTutorial={sidebarLinks[1]}
        nextTutorial={sidebarLinks[3].children[1]}
      />
      <ShareArticle
        title="Create Dashtronaut's animated stars using Flutter's CustomPainter and animations"
        url="https://dashtronaut.app/tutorials/background-stars/"
      />
      <GetTheAppSection />
    </>
  )
}

BackgroundStarsPage.Layout = TutorialsLayout

export default BackgroundStarsPage
